import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../utils/media-queries';

const Footer = () => {
  const { footer } = useStaticQuery(graphql`
    query Footer {
      footer: allSanityContact {
        nodes {
          mail
        }
      }
    }
  `);

  const [year, setYear] = useState();
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <StyledFooter className="grid hl footer">
      <div className="left">
        <p>EJR</p>
        <p>
          <a href={`mailto:${footer.nodes[0].mail}`} className="hl-hover">
            Kontakt
          </a>
        </p>
      </div>
      <div className="right">
        <p className="hl-hover">
          <Link to="/dsgvo">DSGVO</Link>
        </p>
        <p className="hl-hover">
          <Link to="/impressum">Impressum</Link>
        </p>
        <p>©{`${year}`}</p>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  padding: var(--sp-5) 0;

  .left {
    padding-left: var(--sp-13);
    padding-bottom: var(--sp-2);
    z-index: 1;
  }

  .right {
    padding-left: var(--sp-3);
  }

  @media ${media.L} {
    padding: var(--sp-13) 0;

    display: grid;

    .left {
      grid-column: 3 / 15;
      padding-left: 0;
      padding-bottom: 0;
    }

    .right {
      grid-column: 17 / 24;
      padding-left: 0;
    }
  }
`;

export default Footer;
