import { createGlobalStyle } from 'styled-components';
import Founders from '../assets/fonts/founders-grotesk-web-regular.woff2';
import { media } from '../utils/media-queries';

const GlobalStyles = createGlobalStyle`

  html {
    font-family: var(--f-sans);
    color: var(--b);
    background: var(--b);
    font-size: 10px;
  }

  body {
    font-size: var(--fs-copy);
    line-height: var(--lh);
    letter-spacing: var(--ls);
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .main-wrapper {
    background: var(--w);
  }
  
  /* Typography */

  .breakword {
    word-break: break-word;
    hyphens: auto;
  }
  
  @font-face {
    font-family: Founders;
    src: url(${Founders});
  }
  
  .hl {
    font-size: var(--fs-hl);
    line-height: var(--lh-hl-fix);
    letter-spacing: var(--ls-hl-fix);

    @media ${media.M} {
      line-height: var(--lh-hl);
      letter-spacing: var(--ls-hl);
    }
  }

  .hl-hover {
    transition: 0.3s;

    &:hover {
      color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--b);
    }
  }

  .hl-fix {
    font-size: var(--fs-hl-fix);
    line-height: var(--lh-hl-fix);
    letter-spacing: var(--ls-hl-fix);
  }

  .outl {
    color: var(--w);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--b);
  }

  .outl-b {
    color: var(--b);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--w);
  }

  .outl-c {
    color: var(--b);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fab8b8;
  }

  /* Images */

  .gatsby-image-wrapper {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .iframe {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /* Scrollbar */

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* Layout */


  .grid {
    display: grid;
    grid-template-columns: repeat(var(--g-cols), 1fr);
    gap: var(--sp-1);

    * {
      grid-column: 1 / calc(var(--g-cols) + 1);
    }
  }

  .underl {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 1px;
      height: 1px;
      width: 100%;
      background: var(--b);
    }
  }

  .underl-w {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 1px;
      height: 1px;
      width: 100%;
      background: var(--w);
    }
  }
`;

export default GlobalStyles;
