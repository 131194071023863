import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalStyles from '../../styles/globalStyles';
import Normalize from '../../styles/normalize';
import Variables from '../../styles/variables';
import Footer from '../molecules/footer';
import LayoutProvider from './layoutProvider';
import { ease } from '../../utils/easing';

const Layout = ({ children, location }) => (
  <LayoutProvider>
    {/**
     * Styles
     */}
    <Normalize />
    <GlobalStyles />
    <Variables />
    {/**
     * Content
     */}
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.main
        key={location && `key${location.pathname}`}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{
          duration: 0.4,
          ease: ease.inOutSmooth,
        }}
      >
        <div className="main-wrapper">
          {children}

          {!(
            location.pathname === '/projekte' ||
            location.pathname === '/projekte/' ||
            location.pathname === '/kontakt' ||
            location.pathname === '/kontakt/'
          ) && <Footer />}
        </div>
      </motion.main>
    </AnimatePresence>
  </LayoutProvider>
);

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default Layout;
